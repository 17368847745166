<template>
  <div class="app-header">
    <div class="header-content">
        <div class="headlerlogotitle" @click="imgClick()">
          <el-image style="width: 56px" :src="require('@@/assets/logo.png')"></el-image>
          <div style="text-align: center">
            <span style="font-size: 26px">国资智采<br></span>
            <span style="font-size: 80%">电子招投标交易平台</span>
          </div>
        </div>
      <div class="header-elMenu">
        <el-menu mode="horizontal" :default-active="current" class="headmenu" active-text-color="#F18C1D" text-color="#333333" @select="handleSelect">
          <template v-for="item in menuItems">
            <el-menu-item v-if="!item.children || !item.children.length" :key="item.location" :index="item.location" style="height: 60px;">
              <a v-if="item.label == 'CA在线办理'" href="javascript:void(0)" @click.prevent="gotoCA" >{{ item.label }}</a>
              <a v-else :href="item.location" @click.prevent >{{ item.label }}</a>
            </el-menu-item>
            <el-submenu v-else :key="item.location" :index="item.location">
              <template slot="title"><span >{{ item.label }}</span></template>
              <el-menu-item v-for="child in item.children" :key="child.location" style="height: 60px;">
                <a :href="child.location" @click.prevent>{{ child.label }}</a>
              </el-menu-item>
            </el-submenu>
          </template>
        </el-menu>
      </div>
<!--      <div class="header-button">-->
<!--        <el-button type="danger" size="mini" @click="$router.push('/login?type=4')">业务协同平台</el-button>-->
<!--      </div>-->
<!--        <div class="head_dv">-->
<!--          <div style="width: 15%">-->
<!--            <img style="width: 95%" src="../assets/phone.png">-->
<!--          </div>-->
<!--          <div style="width: 75%">-->
<!--            <p style="font-size: 130%">400-1166-160</p>-->
<!--            <p style="font-size: 15px;">(工作时间：8:30-18:00)</p>-->
<!--          </div>-->
<!--        </div>-->
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      showbox: true,
      usertype: "0",
      topath: "",
      current: "/home",
      islogin: false,//是否已登陆
      menuItems: [
        {
          label: "首页",
          location: "/home",
        },
        {
          label: "交易信息",
          location: "/about",
        },
        {
          label: "保函系统",
          location: "/guarantee",
        },
        {
          label: "开评标室预约",
          location: "/bidEvaluationOffice",
        },
        // {
        //   label: "CA在线办理",
        // },
        // {
        //   label: "帮助中心",
        //   location: "/help",
        // },
        {
          label: "下载中心",
          location: "/download",
        },
        {
          label: "平台专家库",
          location: "/myExpert",
        },
        {
          label: "关于我们",
          location: "/ourself",
        },
      ],
    };
  },
  created() {
    // if (Cookies.get('Admin-Token')) this.islogin = true;
    let menu = sessionStorage.getItem("menu");
    this.current = menu ? (menu == "/" ? "/home" : menu) : "/home";
  },
  mounted() {
    this.$bus.$on("changeMainNav", (data) => {
      this.current = data
    });
  },
  methods: {
    gotoCA(){
      window.open("https://hebcaonline.hebca.com/Hebca/foreground/eseal/applyCertAndAutoSignPage.action?projectType=gzzcdzzb&cztype=1&certType=null")
    },
    // 顶部导航跳转
    handleSelect(path) {
      this.toPage(path);
    },
    // 页面跳转
    toPage(path, val) {
      if (path == '/guarantee') window.open('guarantee/')
      if (path == '/bidEvaluationOffice') window.open('/bidEvaluationOffice/')
      this.$router.push({path: path, query: {type: val}});
    },
    imgClick(){
      this.$router.push({path: "/home",});
    },
  },
  watch: {
    '$route': {
      immediate: true,
      handler(n) {
        if (n.path.indexOf('about') != -1) {
          this.$nextTick(() => this.current = '/about')
        } else {
          this.$nextTick(() => this.current = n.path)
        }
      }
    }
  }
};
</script>
<style scoped lang="less">
.app-header{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 80px;

}
.header-content{
  max-width: 1340px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;

}
.headlerlogotitle {
  max-width: 320px;
  min-width:200px ;
  color: #f18c1d;
  font-weight: bold;
  display: flex;
  justify-content: right;
  align-items: center;
}
.header-elMenu{
 width: calc(100% - 600px);
}
 ::v-deep .el-menu{
   width: 100% ;
   display: flex;
   justify-content: space-between;
   align-items: center;
 }
  ::v-deep .el-menu-item {
    padding: 0 3px;
    height: 46px;
    margin: 0 10px;
    font-size: 18px;
  }
::v-deep .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  & > div {
    height: inherit;
    align-items: center;
  }

::v-deep .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 4px solid rgb(241, 140, 29);
}
.head_dv{
  max-width: 240px;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: orange;
  text-align: center;
}
</style>
