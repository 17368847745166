import agent from '@/assets/images/homeImages/agent.png'
import  bidder from '@/assets/images/homeImages/bidder.png'
import supervise from  '@/assets/images/homeImages/supervise.png'
import owner from  '@/assets/images/homeImages/owner.png'
import expert from '@/assets/images/homeImages/expert.png'
import  agency from '@/assets/images/homeImages/agency.png'
import bidderBackground from '@/assets/images/homeImages/bidderBackground.png'
import ownerBackground from '@/assets/images/homeImages/ownerBackground.png'
import expertBackground from '@/assets/images/homeImages/expertBackground.png'
import kpbs from '@/assets/images/homeImages/kpbs.png'
import pbzj from '@/assets/images/homeImages/pbzj.png'
import tbfu from '@/assets/images/homeImages/tbfu.png'
import lunbo from '@/assets/images/homeImages/lunbo.png'
export const home = {
    agent, bidder, supervise, owner, expert, agency,
    bidderBackground, ownerBackground, expertBackground, kpbs, pbzj, tbfu,lunbo
}
import kf from '@/assets/images/rightMenuImages/kf.png'
import ewm from '@/assets/images/rightMenuImages/ewm.png'
import fhdb from '@/assets/images/rightMenuImages/fhdb.png'
import apkCode from '@/assets/images/rightMenuImages/apkCode.png'
export const rightMenu = {
    kf,ewm,fhdb,apkCode
}
import Group from '@/assets/images/bottomText/Group.png'
import dh from '@/assets/images/bottomText/dh.png'
import yx from '@/assets/images/bottomText/yx.png'
export const bottomText = {
    Group,dh,yx
}