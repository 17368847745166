<template>
  <div class="page_home">
    <div class="page_header">
      <div class="home_lunbo">
        <el-carousel height="390px">
          <el-carousel-item v-for="(items,index) in imagelist" :key="index">
            <el-image  @click="carouselClick(items)" :src="items.pictureUrl" style="width:100%;height:inherit" fit="fill"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <div class="page_content">
      <div class="home_content">
        <div style="padding: 0 20px;background-color:#fff;width:calc(100% - 370px);margin-right: 20px;">
            <div style="margin: 20px auto; ">
                <div class="headerTitleClass2" >
                  <h3>交易信息<span style="float: right;font-weight: normal;font-size: 14px;color: #999;cursor:pointer" @click="skipJY()">更多<i class="el-icon-arrow-right"></i></span></h3>
                </div>
            </div>
          <el-tabs style="min-height: 500px;" v-model="transactionQueryParams.ggAnnouncement" type="card" @tab-click="handleClick">
            <el-tab-pane v-for="(item, index) in leftTitleList" :key="index" :label="item.label" :name="item.value" class="tabPaneClass scroll">
              <ul style="">
                <li class="flex_content ispointer texthover" v-for="(item, index) in transactionList" :key="index" style="margin-bottom: 25px;font-size: 15px" @click="goToPage(item.id)">
                  <div style="width: 80%">
                    <span>【{{ item.areaName }}】</span>
                    <span v-text="item.ggName"></span>
                  </div>
                  <div style="color: #999" v-text="item.ggStartTime.substring(0,10)"></div>
                </li>
              </ul>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="right" style="background-color:#fff;width: 366px">
          <div class="headerTitleClass2" style="margin: 20px 0 20px 20px;">
            <h3>登录入口</h3>
          </div>
          <div style="display: block;width: 90%;margin: 0 auto 10px; cursor:pointer;" :underline="false" v-for="(item, index) in imagelist1" @click="routerToClick(item)" :key="index">
            <div style="width: 100%;height: 80px;text-align: center;line-height: 80px;color: #FFFFFF;font-size: 20px;position: relative;margin:0 auto" :style="'background: url(' + item.img +') no-repeat;background-size: 100% 100%;'">
              <div class="graybg"></div>
              <div style="position: absolute;top: 10px;right: 40px;width: 240px;text-align: center;display: flex;flex-direction: row">
                <div style="height: 40px;">
                  <el-image style="height: 30px;" :src="item.icon"></el-image>
                </div>
                <div style="height: 40px;margin: -6px 20px;width: 140px;text-align: left">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card1" style="display: flex;justify-content: space-between;align-items: center;cursor: pointer">
        <div @click="toRouter('/bidEvaluationOffice')">
          <el-image  style="width: 660px;" :src="home.kpbs"></el-image>
        </div>
        <div @click="toRouter('/expert')">
          <el-image  style="width:660px" :src="home.pbzj"></el-image>
        </div>
      </div>
      <div class="home_content">
        <div style="padding: 0 20px;background-color:#fff;width:calc(100% - 370px);;margin-right: 20px;">
          <div style="margin: 20px auto; ">
            <div class="headerTitleClass2" >
              <h3>业主自采<span style="float: right;font-weight: normal;font-size: 14px;color: #999;cursor:pointer" @click="skipJY()">更多<i class="el-icon-arrow-right"></i></span></h3>
            </div>
          </div>
          <div style="display: flex;justify-content: space-between">
              <div  style="display: flex;justify-content: center;align-items: center; cursor: pointer;">
                <div>
                  <div v-for="(item,index) in relationPurchaseTypeList" :key="index">
                    <div class="zbgg-label"  @click="handleQueryZbgg(item.value)" :style="selfProcurementQueryParams.relationPurchaseType === item.value ? 'color:#fff;background-color: #F18C1D' :'background-color: rgba(36, 97, 177, .1)'">
                      <div> {{ item.label }} </div>
                    </div>
                  </div>
                </div>
            </div>
            <div style=" width: calc(100% - 100px);">
              <el-tabs style="min-height: 500px;" v-model="selfProcurementQueryParams.ggAnnouncement" type="card" @tab-click="handleYZClick" v-if="selfProcurementQueryParams.relationPurchaseType !== 'supplier'">
                <el-tab-pane v-for="(item, index) in leftTitleList" :key="index" :label="item.label" :name="item.value" class="tabPaneClass scroll">
                  <ul style="">
                    <li class="flex_content ispointer texthover" v-for="(item, index) in selfProcurementList" :key="index" style="margin-bottom: 25px;font-size: 15px" @click="goToPage(item.id)">
                      <div style="width: 80%">
                        <span v-if="item.areaName">【{{ item.areaName }}】</span>
                        <span v-text="item.ggName"></span>
                      </div>
                      <div style="color: #999" v-text="item.ggStartTime.substring(0,10)"></div>
                    </li>
                  </ul>
                </el-tab-pane>
              </el-tabs>
              <el-tabs style="min-height: 500px;" v-model="collectParams.collectType" type="card" @tab-click="handleQueryCollectgg" v-else>
                <el-tab-pane v-for="(item, index) in collectTypeList" :key="index" :label="item.label" :name="item.value" class="tabPaneClass scroll">
                  <ul style="">
                    <li class="flex_content ispointer texthover" v-for="(item, index) in collectList" :key="index" style="margin-bottom: 25px;font-size: 15px" @click="goToCollectGgDetail(item.id)">
                      <div style="width: 80%">
                        <span v-text="item.ggName"></span>
                      </div>
                      <div style="color: #999" v-text="item.ggStartTime.substring(0,10)"></div>
                    </li>
                  </ul>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
        <div class="right" style="background-color:#fff;width: 366px">
          <div class="headerTitleClass2" style="margin: 20px 0 20px 20px;">
            <h3>采购专区</h3>
          </div>
          <div style="display: block;width: 90%;margin: 0 auto 10px; cursor:pointer;" :underline="false">
            <el-input placeholder="请输入内容" v-model="selfProcurementQueryParams.regionName" class="input-with-select">
              <el-button slot="append" icon="el-icon-search" @click="getRegionYz"></el-button>
            </el-input>
          </div>
          <div style="display: block;width: 90%;margin: 0 auto 10px; cursor:pointer;height: 450px;overflow: hidden;overflow-y: auto;" :underline="false">
            <div v-for="item in specialZoneList" style="padding: 12px 0;font-size: 16px;border-bottom:1px solid #EEEEEE " class="specialZoneCliss" @click="specialZoneClick(item)">
              {{ item.regionName }}
            </div>
          </div>
        </div>
      </div>
      <div class="card1">
        <el-image style="width:100%" :src="home.tbfu"></el-image>
      </div>
      <div class="home_content" >
        <div style="padding:  20px;width:calc(100% - 370px);background-color:#fff;margin-right: 20px;">
          <div style="margin-bottom: 24px;">
            <div class="headerTitleClass2" >
              <h3>新闻公告</h3>
            </div>
          </div>
          <div>
            <ul class="scroll news_list">
              <li style="display: flex;flex-direction: row;margin-bottom: 22px;" v-for="(item, index) in xwlist" :key="index" @click="goNewsDetail(item.id)">
                <div style="width: 15%;height:15%; margin-right: 20px;">
                  <div class="block">
                    <el-image :src="item.industrytupianUrl">
                      <div slot="error" class="image-slot">
                        <el-image :src="require('../assets/images/xw.png')"></el-image>
                      </div>
                    </el-image>
                  </div>
                </div>
                <div style="width: 80%; display: flex;flex-direction: column;justify-content: space-around;">
                  <p class="con_h">{{ item.title }}</p>
                  <p class="flex_content"><span>{{ item.createTime.substring(0, 10) }}</span></p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="right" style="background-color:#fff;width: 366px;box-sizing: border-box;">
          <div class="headerTitleClass2" style="margin: 20px 0 20px 20px;">
            <h3>工具下载</h3>
          </div>
          <div style="display: block;margin: 0 auto 10px;width: 95%;" :underline="false" v-for="(item, index) in toollist"  :key="index">
            <div :class="{'toolitem':true,'action':toolaction===index}" @click="downloadClick(item,index)">
              <div style="width: 100%;height: inherit;text-align: center;display: flex;flex-direction: row;justify-content: space-around;align-items: center;">
                <el-image  style="width: 15%;" :src="item.imageUrl"></el-image>
                <div style="text-align: left;width: 80%;margin-left: 10px;">{{ item.commonName }}</div>
                <div class="aftimg"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {xinwenlist, toolslist, lbimages, getGgList, getCollectList, getRegionYzApi} from "@@/api/api";
import { home } from '@@/utils/images'

export default {

  data() {
    return {
      home:home,
      imagelist: [],
      collectList: [],
      transactionQueryParams : {pageSize: 10, page: 1,ztType:'A',ggAnnouncement:'zbgg'},
      collectParams:{collectType:' ', pageSize: 10, page: 1},
      leftTitleList: [
        {label: "招标公告", id: "1", value: 'zbgg'},
        {label: "变更公告", id: "2", value: 'bggg'},
        {label: "资格预审公告", id: "3", value: 'zgysgg'},
        {label: "中标候选人公示", id: "4", value: 'zbhxrgs'},
        {label: "中标公告", id: "5", value: 'zhongbiaogg'}
      ],
      transactionList: [],
      selfProcurementQueryParams : {pageSize: 10, page: 1,ztType:'T',ggAnnouncement:'zbgg',relationPurchaseType:''},
      selfProcurementList:[],
      relationPurchaseTypeList:[
        {label: "全部公告", id: "1", value: ''},
        {label: "供应商征集", id: "3", value: 'supplier'},
        {label: "询比采购", id: "2", value: 'dispersed'},
        {label: "快速竞价", id: "3", value: 'bidding'},
        {label: "框架协议", id: "3", value: 'frameAgreement'},
      ],
      collectTypeList:[
        {label: "全部", id: "1", value: ' '},
        {label: "供应商征集", id: "2", value: 'GYS'},
        {label: "专家征集", id: "3", value: 'ZJ'},
      ],
      xwlist: [],
      imagelist1: [
        {icon: home.agent, title: "代理机构登录", path: "/login?type=3", img: home.agency},
        {icon: home.bidder, title: "投标人登录", path: "/login?type=2", img: home.bidderBackground},
        {icon: home.supervise, title: "监督系统登录", path: "/superviseLogin", img: home.ownerBackground},
        {icon: home.owner, title: "业主登录", path: "/login?type=1", img: home.ownerBackground},
        {icon: home.expert, title: "评标专家登录", path: "/PBLoginMain", img: home.expertBackground},
      ],
      toollist: [],
      toolaction: '',
      specialZoneList:[],
      specialZoneQueryParams : {pageSize: 10, page: 1,regionName:''},
    };
  },
  created() {

  },
  mounted() {
    this.lb()
    this.getGg();
    this.getyzGg();
    this.xinwen()
    this.tools()
    this.getRegionYz()
  },
  methods: {
    // 跳转公告详情
    goToCollectGgDetail(id) {
      let routeData = this.$router.resolve({path: "/collectGgDetail", query: {id: id}});
      window.open(routeData.href, '_blank');
    },
    handleQueryCollectgg(tab){
      if (tab.name)this.collectParams.collectType = tab.name
      this.getCollectgg();
    },
    async getCollectgg() {
      const { data } = await getCollectList(this.collectParams)
      this.collectList = data.items
    },
   async lb() {
    const { data } = await  lbimages()
     this.imagelist = data.items
    },
   async getGg() {
     const { data } = await getGgList(this.transactionQueryParams)
     this.transactionList = data.items
    },
    handleClick(tab, event) {
     this.transactionQueryParams.ggAnnouncement = tab.name
      this.getGg()
    },
   async getyzGg(val){
     const { data } = await getGgList( this.selfProcurementQueryParams)
     this.selfProcurementList = data.items
    },
    handleYZClick(tab, event){
     this.selfProcurementQueryParams.ggAnnouncement = tab.name
      this.getyzGg()
    },
    handleQueryZbgg(val){
      this.selfProcurementQueryParams.relationPurchaseType = val
      if(val !== 'supplier'){
        this.getyzGg()
      }else {
        this.getCollectgg();
      }
    },
    //查询新闻列表
    async xinwen() {
      const {data} = await xinwenlist()
      this.xwlist =data.items
    },
    routerToClick(row) {
      this.$router.push(row.path)
    },
    //查询工具栏列表
   async tools() {
     const {data} = await toolslist()
     this.toollist = data.items

    },
    carouselClick(item){
      window.open(item.jumpAddress, '_blank');
    },
    goToPage(id) {
      let routeData = this.$router.resolve({path: "/ggDetail", query: {id: id}});
      window.open(routeData.href, '_blank');
    },
    goNewsDetail(id) {
      let routeData = this.$router.resolve({path: "/newsDetail", query: {id: id}});
      window.open(routeData.href, '_blank');
    },
    skipJY(val) {
      this.$router.push({path: "/about", query: {id: val ? '11' : '1'}});
      this.$bus.$emit("changeMainNav", "/about");
    },
    downloadClick(row, index) {
      window.open(row.commonTool)
    },
   async getRegionYz(){
      const {data} = await getRegionYzApi(this.specialZoneQueryParams)
     this.specialZoneList = [{ id:'1',regionName:'河北旭城智联供应链有限公司' },...data.items]

    },
    specialZoneClick(val){
      let routeData = this.$router.resolve({path: '/specialZone',query:{id: val.id}});
      window.open(routeData.href, '_blank');
    },
    toRouter(val){
      let routeData = this.$router.resolve({path: val,});
      window.open(routeData.href, '_blank');
    }
  },
};
</script>
<style scoped>
.page_home{
  background-color: #F3F9FD;
  padding-bottom: 50px;
}
.page_header{
  width: 100%;
  height: 390px;
  margin: 0 auto;
  padding: 10px 0;
}
.home_lunbo{
  width: 100%;

}
.page_content{
  width: 1340px;
  margin: 0 auto;
  padding: 10px 0;;
  border: 1px solid #0000;
}
.home_content{
  display: flex;
  margin: 10px auto;
  width: 100%;
  justify-content: space-between;
}

.news_list li {
  cursor: pointer;
}

.news_list li:hover .con_h{
  color: #F18C1D;
}
.con_h {
  font-size: 100%;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.toolitem{
  background-color:#ffffff;
  transition:all 0.5s;
  width: 300px;
  padding: 6px;
  height: 80px;
  border: 1px solid #EEEEEE;
  border-radius: 6px;
  text-align: center;
  font-size: 20px;
  margin: 0 auto 13px;
  cursor: pointer;
  color: #333333;
  position: relative;
}
.aftimg{
    width: 30px;
  height: 30px;
  background: url(@@/assets/images/icon9.png) no-repeat;
  background-size: 100% 100%;
}
.toolitem.action .aftimg, .toolitem:hover .aftimg{
  background: url(../assets/images/icon8.png) no-repeat;
  background-size: 100% 100%;
}


.toolitem.action, .toolitem:hover {
  background-color: #F18C1D ;
  border-color: #F18C1D;
  /*color: #FFFFFF;*/
  border-radius: 10px;

}


.headerTitleClass2 {
  padding-left: 17px;
  padding-bottom: 18px;
  border-bottom: 1px solid #eee;
  position: relative;
  margin-bottom: 18px;
}
.headerTitleClass2::before {
  content: '';
  width: 6px;
  height: 22px;
  background: #F18C1D;
  position: absolute;
  left: 0;
  top: 2px;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item, ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav, ::v-deep .el-tabs--card > .el-tabs__header {
  border: none;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  height: 40px;
  line-height: 40px;
  background: #F18C1D;
  border-radius: 2px;
  color: #fff;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:hover {
  /*width: 98px;*/
  height: 40px;
  line-height: 40px;
  background: #F18C1D;
  border-radius: 2px;
  color: #fff;
}

.card1 {
  height: 180px;
  width: 100%;
  margin: 24px auto;
}
h3{
  color: #F18C1D;
}
::v-deep .el-collapse-item__header{
  background-color:#ccffff  !important;
  font-size: 15px !important;
  font-weight: bolder;
  border-radius: 5px;
  margin-bottom:20px ;
  padding: 10px;
}
.block{
  width: 100%;
}
.graybg {
  background-color: rgba(0, 0, 0, 0.5);
  width: inherit;
  height: inherit;
}
.zbgg-label{
  width: 90px;
  height: 80px;
  text-align: center;
  margin: 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  //border-radius: 10px;
}
.specialZoneCliss:hover{
  color:#FC7C3A  ;
}
</style>
