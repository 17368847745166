<template>
  <div class="app-footer">
    <div class="footer-content">
      <div class="footer-NavMenu">
        <div v-for="item in footeRouter" >
          <div style="font-size: 18px;margin-bottom: 20px"> {{ item.label }} </div>
          <div v-for="items in item.children" style="padding-bottom: 16px;font-size: 10px;font-style: normal;text-transform: none;text-align: left">
            {{ items.label }}
          </div>
        </div>
      </div>
      <div>
        <div>
          <el-image style="width: 240px" :src="bottomText.Group"></el-image>
        </div>
        <div>
          <div style="margin: 10px 0;display: flex;align-items: center" ><el-image style="width: 16px;height: 16px;margin-right: 8px" :src="bottomText.dh"></el-image>联系我们 400-1166-160</div>
          <div style="margin: 10px 0;display: flex;align-items: center"><el-image style="width: 16px;height: 16px;margin-right: 8px" :src="bottomText.yx"></el-image>邮箱地址 zczckjfzyxgs@163.com</div>
          <div style="margin: 10px 0;display: flex;align-items: center"><el-image style="width: 16px;height: 16px;margin-right: 8px" :src="bottomText.yx"></el-image>公司地址 石家庄新华区荣鼎天下</div>
        </div>
      </div>
      <div style="text-align: center">
        <el-image style="width: 111px" :src="rightMenu.apkCode"></el-image>
        <div>联系我们</div>
      </div>
    </div>
    <div class="bq">
      <span>版权声明&nbsp;&nbsp;&nbsp;&nbsp;  </span>
      <span><el-link href="https://beian.miit.gov.cn/" target="_blank" style="text-decoration: underline"> 冀ICP备2022024858号 </el-link></span>
      <span><el-link href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13010502002114" target="_blank"
                     style="text-decoration: underline; padding-left: 20px;">
          <img src="https://www.gzzczb.com/m/test/static/images/gwab.png" style="float: left;padding-right: 5px;">
           冀公网安备 13010502002114号 </el-link>
        </span>
      <span> &nbsp;&nbsp;&nbsp;&nbsp;北京中财智采科技发展有限公司河北分公司 </span>
    </div>
  </div>
</template>

<script>
import { bottomText,rightMenu } from "@/utils/images";
import Group from "@/assets/images/bottomText/Group.png";

export default {
  computed: {
    Group() {
      return Group
    }
  },
  components: {  },
  data() {
    return {
      footeRouter:[
        {label: "首页", location: "",key:'1',
          children:[
            {label: "交易中心", location: "",key: '2-1',},
            {label: "新增板块", location: "",key: '2-2',},
            {label: "下载中心", location: "",key: '2-3',},
            {label: "新闻中心", location: "",key: '2-4',},
            {label: "联系我们", location: "",key: '2-5',},
          ]},
        {label: "交易中心", location: "",key: '2',
          children:[
            {label: "交易信息", location: "",key: '2-1',},
            {label: "业主自采", location: "",key: '2-2',},
          ]
        },
        {label: "保函系统", location: "/",key: '3',
          children:[
            {label: "申请保函", location: "",key: '3-1',},
            {label: "保函验真", location: "",key: '3-2',},
            {label: "法律政策", location: "",key: '3-3',},
            {label: "平台入住", location: "",key: '3-4',},
          ]
        },
        {label: "开评标室预约", location: "/",key: '4',
          children:[
            {label: "区域中心", location: "",key: '4-1',},
          ]
        },
        {label: "下载中心", location: "/",key: '5',
          children:[
            {label: "常用工具", location: "",key: '5-1',},
            {label: "帮助中心", location: "",key: '5-2',},
          ]
        },
      ],
      bottomText:bottomText,
      rightMenu
    };
  },
  methods: {

  }
};
</script>
<style>
.app-footer{
  background-color: #2C3345;
  width: 100%;
  height: 290px;
  color: #FFFFFF;
}
.footer-content{
  width: 1340px;
  margin:  auto;
  display: flex;
  justify-content: space-between;
  height: 250px;
  align-items: center;
}
.footer-NavMenu{
  width: calc(100% - 580px);
  display: flex;
  justify-content: space-between;
}
.bq{
  background-color: #1e1e1e;
  text-align: center;
  height: 40px;
  line-height: 40px;
}
</style>
