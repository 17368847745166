<template>
  <div class="page-right">
    <div class="right-content" style="background-color:#FF8A00 " @mouseover="setMouseleave(1)" @mouseleave="showTip = false">
      <div>
        <el-image style="width: 32px" :src="rightMenu.kf"></el-image>
      </div>
      <div style="color:#fff;">联系客服</div>

    </div>
    <div class="right-content" style="background-color:#0492FF" @mouseover="setMouseleave(2)" @mouseleave="showTip = false">
      <div>
        <el-image style="width: 32px" :src="rightMenu.ewm"></el-image>
      </div>
      <div style="color:#fff;">智采管家</div>
    </div>
    <div class="right-content" style="background-color:#fff" @click="toTop">
      <div>
        <el-image style="width: 32px" :src="rightMenu.fhdb"></el-image>
      </div>
      <div style="">返回顶部</div>
    </div>
    <div v-show="showTip" class="tooltip">
      <div v-if="showTipType === 1" class="">
        <div>客服电话</div>
        <div>400-1166-160</div>
        <div>工作时间</div>
        <div>(8:30-18:00)</div>
      </div>
      <div v-else>
        <el-image style="width: 100%" :src="rightMenu.apkCode"></el-image>
      </div>
    </div>
  </div>
</template>
<script>
import { rightMenu } from "@/utils/images";

export default {
  data() {
    return {
      rightMenu:rightMenu,
      showTip:false,
      showTipType:1,
    };
  },
  methods: {
    setMouseleave(val){
      this.showTip = true
      this.showTipType = val
    },
    toTop(){
      document.getElementsByClassName('app_content')[0].scrollTop = 0
    }
  }
};
</script>
<style lang="less" scoped>
.page-right{
  width: 64px;
  position: fixed;
  right: 20px;
  top: 550px;
  align-items: flex-end;
  z-index: 99;
}
.right-content{
  width: 100%;
  height: 76px;
  margin-bottom: 12px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tooltip {
  position: absolute;
  top: 20px;
  background: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  transform: translateX(calc(-100% - 5px));
  box-shadow: 0 1px 5px gray;
  width: 136px;
  font-size: 14px;
}
</style>
